<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        쳬육시설 예약하기
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">

    <!-- main-content -->
    <div class="main-content main-component">

      <div class="">
        <div class="view-contents">

<!--
          <article class="content-section section-intro">
            <p class="text font-body14 text-muted">신청자 수정은 안내사항 동의서에서 수정하시고 동의서에 동의하시기 바랍니다.</p>
            <button class="kb-btn-content-item mt-3">안내사항 동의서</button>
          </article>
-->
          <!-- content-section: -->
          <article class="content-section">
            <header class="section-header">
              <h3 class="title">예약정보</h3>
            </header>
            <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
              <!-- kb-form-field:이용일시 -->
              <div class="kb-form-field field-first">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">이용일시</span></label>
                </div>
                <div class="kb-form-row">
                  <span class="kb-form-text">{{ fcltRsvtAplyIns.reserveDate }} ・ {{ fcltRsvtAplyIns.reserveDateTime.time}}</span>
                </div>
              </div>
              <!-- kb-form-field:신청자 1 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 1</span></label>
                </div>
                <div class="kb-form-row">
                  <span class="kb-form-text">{{ fcltRsvtAplyIns.aplcntDeptNm }} ・ {{ fcltRsvtAplyIns.aplcntNm }} | {{ fcltRsvtAplyIns.aplcntId }}</span>
                </div>
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 1 생년월일</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="text" maxlength="6" class="kb-form-control" v-model="brdt" placeholder="생년월일 6자리를 입력하세요." />
                </div>
              </div>
              <!-- kb-form-field:신청자 2 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2</span></label>
                </div>
                <div class="kb-form-row">
                  <span class="kb-form-text">{{ fcltRsvtAplyIns.aplcntDeptNm2 }} ・ {{ fcltRsvtAplyIns.aplcntNm2 }} | {{ fcltRsvtAplyIns.aplcntId2 }}</span>
                </div>
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2 생년월일</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="text" maxlength="6" class="kb-form-control" v-model="brdt2" placeholder="생년월일 6자리를 입력하세요." />
                </div>
              </div>
              <!-- kb-form-field:이용인원 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">이용인원</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="number" class="kb-form-control" v-model="aplyPeopl" placeholder="이용인원을 입력하세요." />
                  <div class="kb-form-side">명</div>
                </div>
              </div>
              <!-- kb-form-field:사무실 연락처 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자1 연락처</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="tel" maxlength="12" class="kb-form-control" v-model="aplcntTelno" />
                </div>
              </div>
              <!-- kb-form-field:휴대폰 연락처 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자2 연락처</span></label>
                </div>
                <div class="kb-form-row">
                  <input type="tel" maxlength="12" class="kb-form-control"  v-model="aplcntTelno2" />
                </div>
              </div>
              <!-- kb-form-field:부점/단체명 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">부점/단체명</span></label>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-dropdown" :class="{'is-active' : grpToggle}" >
                    <button class="kb-form-dropdown-btn" @click="grpToggle=!grpToggle"><span class="text">{{ grpDivNm }}</span><i class="icon-arrow"></i></button>

                    <div class="kb-form-dropdown-options" style="position:absolute; left:0; width:100%;">
                      <div class="dropdown-option">
                        <ul class="dropdown-option-list">
                          <li class="dropdown-option-item" v-for="item in codeList" :key="item.cdSn">
                            <a @click="selectGrp(item)" href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">{{ item.cdNm }}</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                  <input type="text" class="kb-form-control left-margin" v-model="grpNm" />

                  <!--
                  <div class="kb-form-dropdown" :class="{'is-active' : usePeridToggle}" data-offset="bottom-left">
                    <button class="kb-form-dropdown-btn" @click="usePeridToggle=!usePeridToggle"><span class="text">{{ usePeriodTxt }}</span><i class="icon-arrow"></i></button>

                    <div class="kb-form-dropdown-options" style="width: 358px;">
                      <div class="dropdown-option">
                        <ul class="dropdown-option-list">
                          <li class="dropdown-option-item" v-for="item in usePeridOption" :key="item.value">
                            <a href="javascript:" class="dropdown-option-link" @click="selectUsePeriod(item)">
                              <span class="dropdown-option-text">{{ item.label }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  -->
                </div>
                <div class="kb-form-row">
                  <span class="kb-form-text text-muted font-body14">허위일 경우 향후 6개월간 이용제한</span>
                </div>
              </div>
              <!-- //kb-form-field -->
            </div>
          </article>


        </div>
      </div>
      <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="nextStep">예약</button></div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, ref,watch} from 'vue';
import {useStore} from "vuex";

import {useRouter} from "vue-router";
import {ACT_SET_FCLT_APLY_MOBILE_INFO} from "@/store/modules/trnct/trnct";
import {useAlert} from "@/assets/js/modules/common/alert";
import {isTelFormat,isHpTelFormat} from "@/assets/js/util";

export default {
  name: 'DailyMobileSportsApplyStep3',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {showMessage} = useAlert();

    const session = computed(() => store.state.auth.session);
    const fcltRsvtAplyIns = computed(() => store.state.trnct.mobileSportsApplyInfo);
    const codeList = computed(() =>
        store.state.code.codes.filter(code => code.upCd === '2095000' && code.cdNm === '당행 동호회'));

    codeList.value.unshift({cd: '', cdNm: '단체구분'});

    const reserveVenue = ref('체육시설');

    const brdt = ref(store.state.trnct.mobileSportsApplyInfo.brdt);
    const brdt2 = ref(store.state.trnct.mobileSportsApplyInfo.brdt2);
    const aplyPeopl = ref(store.state.trnct.mobileSportsApplyInfo.aplyPeopl);
    const aplcntTelno = ref(store.state.trnct.mobileSportsApplyInfo.aplcntTelno);
    const aplcntTelno2 = ref(store.state.trnct.mobileSportsApplyInfo.aplcntTelno2);
    const grpDivCd = ref(store.state.trnct.mobileSportsApplyInfo.grpDivCdDcd);
    const grpDivNm = ref(store.state.trnct.mobileSportsApplyInfo.grpDivNm);
    const grpToggle = ref(false);
    const grpNm = ref(store.state.trnct.mobileSportsApplyInfo.grpNm);

    const selectGrp = (item) => {
      grpToggle.value = !grpToggle.value;
      grpDivCd.value= item.cd;
      grpDivNm.value= item.cdNm;
    };


    const nextCheck = () => {
      console.log('dd');
    }

    const nextStep = () => {
      if(brdt.value == null  || brdt.value == ''){
        showMessage('신청자1 생년월일 6자리를 입력해주세요');
        return;
      }

      if(brdt2.value == null  || brdt2.value == ''){
        showMessage('신청자2 생년월일 6자리를 입력해주세요');
        return;
      }

      if(aplyPeopl.value == null  || aplyPeopl.value == '' || aplyPeopl.value == 0){
        showMessage('이용인원을 입력해주세요');
        return;
      }

      if(aplcntTelno.value == null  || aplcntTelno.value == ''){
        showMessage('신청자 1 연락처를 입력해주세요');
        return;
      }

      if(!isTelFormat(aplcntTelno.value) && !isHpTelFormat(aplcntTelno.value) ){
        showMessage('신청자 1 연락처가 잘못되었습니다.');
        return false;          
      }            
          

      if(aplcntTelno2.value == null  || aplcntTelno2.value == ''){
        showMessage('신청자 2 연락처를 입력해주세요');
        return false;
      }

      if(!isTelFormat(aplcntTelno2.value) && !isHpTelFormat(aplcntTelno2.value) ){
        showMessage('신청자 2 연락처가 잘못되었습니다.');
        return false;          
      }            
                

      if (aplcntTelno.value ==aplcntTelno2.value) {
        showMessage('신청자 1, 신청자 2 연락처를 동일하게 입력할 수 없습니다.');
        return;
      }              

      if(grpDivCd.value == null  || grpDivCd.value == ''){
        showMessage('단체를 선택해주세요');
        return;
      }

      if(grpNm.value == null  || grpNm.value == ''){
        showMessage('단체명을 입력해주세요.');
        return;
      }

      store.commit(`trnct/${ACT_SET_FCLT_APLY_MOBILE_INFO}`,
          {
              brdt: brdt.value,
              brdt2: brdt2.value,
              aplyPeopl: aplyPeopl.value,
              grpDivCdDcd: grpDivCd.value,
              grpDivNm: grpDivNm.value,
              grpNm: grpNm.value,
              aplcntTelno: aplcntTelno.value,
              aplcntTelno2: aplcntTelno2.value,
          });

      router.push('/daily/apply/sports/step4');
    }

    const goBack = () => {
      router.go(-1);
    }

    watch(() => fcltRsvtAplyIns.value.aplcntTelno, () => {
      fcltRsvtAplyIns.value.aplcntTelno = (fcltRsvtAplyIns.value.aplcntTelno==null? "": fcltRsvtAplyIns.value.aplcntTelno).replace(/[^0-9]/g, '')
      
        
    })
    watch(() => fcltRsvtAplyIns.value.aplcntTelno2, () => {      
      fcltRsvtAplyIns.value.aplcntTelno2 = (fcltRsvtAplyIns.value.aplcntTelno2==null? "": fcltRsvtAplyIns.value.aplcntTelno2).replace(/[^0-9]/g, '')        
    })

    watch(() => fcltRsvtAplyIns.value.brdt, () => {      
      fcltRsvtAplyIns.value.brdt = (fcltRsvtAplyIns.value.brdt==null? "": fcltRsvtAplyIns.value.brdt).replace(/[^0-9]/g, '')        
    })    


    watch(() => fcltRsvtAplyIns.value.brdt2, () => {      
      fcltRsvtAplyIns.value.brdt2 = (fcltRsvtAplyIns.value.brdt2==null? "": fcltRsvtAplyIns.value.brdt2).replace(/[^0-9]/g, '')        
    })    
    return {
      reserveVenue,
      session,
      nextCheck,
      nextStep,
      brdt,
      brdt2,
      aplyPeopl,
      aplcntTelno,
      aplcntTelno2,
      fcltRsvtAplyIns,
      grpDivCd,
      grpDivNm,
      grpToggle,
      grpNm,
      codeList,
      selectGrp,
      goBack
    };
  },
};
</script>
